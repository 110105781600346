import { getBusinessUnit } from "@shared/Utils/analytics-utils";
import { pushToDataLayer } from "../data/data-layer.facade";
import { LoginStates, LoginStateValues } from "Scripts/Src/Components/IceCustomerLogin/customer-login-interfaces";

export type BaseGA4LoginData = {
  user_status: "logged-in" | "not-logged-in";
  user_id: string;
  business_unit: "B2C" | "B2B";
};

export const sendGA4LoginData = (loginState: LoginStateValues, user_id?: string) => {
  const isUserSignedIn =
    loginState === LoginStates.SignedIn ||
    loginState === LoginStates.SignedInRedirect ||
    loginState === LoginStates.SignedInSilent;

  const event: BaseGA4LoginData = {
    user_status: isUserSignedIn ? "logged-in" : "not-logged-in",
    user_id: user_id || "undefined",
    business_unit: getBusinessUnit(),
  };

  pushToDataLayer(event);
};
