import { ComponentChildren, FunctionalComponent, JSX, h } from "preact";
import { useEffect, useState } from "preact/hooks";

type VisuallyHiddenProps = JSX.HTMLAttributes<HTMLSpanElement> & {
  children: ComponentChildren;
  className?: string;
};

export const VisuallyHidden: FunctionalComponent<VisuallyHiddenProps> = ({
  children,
  className = "",
  ...delegated
}: VisuallyHiddenProps) => {
  const [forceShow, setForceShow] = useState(false);

  useEffect(() => {
    if (process.env.ENV === "production") return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Alt") {
        setForceShow(true);
      }
    };
    const handleKeyUp = () => {
      setForceShow(false);
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  if (forceShow) {
    return (
      <span
        style={{
          color: "black",
          background: "var(--yellow-60)",
          fontSize: "0.875rem",
        }}
      >
        {children}
      </span>
    );
  }

  return (
    <span className={`visually-hidden ${className}`} {...delegated}>
      {children}
    </span>
  );
};
