import { createRef, h, type JSX } from "preact";
import { useEffect, useState } from "preact/hooks";
import { IceAuthService } from "./auth-service";
import { CSSTransition } from "preact-transitioning";
import { type AuthState, type CustomerLoginSettings, LoginStates, MenuViewState } from "./customer-login-interfaces";
import { KEY_NAME } from "@shared/Utils/key-names";
import { ShowCompanyAdmin } from "./company-admin";
import { getCustomerDetails } from "./customer-login-services";
import { CloseWhenClickOutSideMenu, keyDownMenuHandler } from "./customer-login-helpers";
import { VisuallyHidden } from "@shared/Components/VisuallyHidden/visually-hidden";
import { HeaderToolTip } from "@shared/Components/HeaderToolTip/headerToolTip";
import { sendGA4LoginData } from "@shared/Analytics/content/login-analytics";

export const CustomerLoginLoader = ({
  userManager,
  minSideBaseUrl,
  minSideProfil,
  orderformApi,
  applicationInsightsService,
}: CustomerLoginSettings): JSX.Element => {
  const [LoggedIn, setLoggedIn] = useState<MenuViewState>(MenuViewState.hidden);
  const [authService, setAuthService] = useState<IceAuthService | null>(null);
  const [authState, setAuthState] = useState<AuthState | null>(null);
  const [showProfilMenu, setShowProfilMenu] = useState<boolean>(false);
  const [closingProfilMenu, setClosingProfilMenu] = useState<boolean>(false);
  const [hasCompanyAdmin, setHasCompanyAdmin] = useState<boolean>(false);
  const [buttonFocus, setButtonFocus] = useState<boolean>(false);
  const [showLoggedOutButton, setShowLoggedOutButton] = useState<boolean>(false);
  const profileMenuContainer = createRef<HTMLDivElement>();
  const profileMenu = createRef<HTMLDivElement>();
  const toggleButton = createRef<HTMLButtonElement>();

  const [message, setMessage] = useState<string>("");

  const userUpdated = (authState: AuthState) => {
    setAuthState(authState);

    sendGA4LoginData(authState.loginState, authState.user?.userId);

    if (authState.loginState === LoginStates.notSignedIn || authState.loginState === LoginStates.SignedOutSilent) {
      setLoggedIn(MenuViewState.closed);
      setMessage("Du har blitt logget ut");

      return;
    }

    setLoggedIn(MenuViewState.open);

    getCustomerDetails(authState.token, orderformApi)
      .then((customer) => {
        setHasCompanyAdmin(customer.isCompanyAdmin);
      })
      .catch((err) => {
        console.error("Could not load customer details");
        applicationInsightsService.trackExceptionWarning(err as Error, "Could not load customer details service");
      });

    if (authState.loginState === LoginStates.SignedInRedirect) {
      setMessage(`Du har blitt logget inn med brukeren ${authState?.user?.fullName}`);
      setShowProfilMenu(true);
    }

    if (authState.loginState === LoginStates.SignedInRedirect) {
      //set focus on tooglebutton
      setButtonFocus(true);
    }
  };

  useEffect(() => {
    setShowLoggedOutButton(LoggedIn === MenuViewState.closed ? true : false);
  }, [LoggedIn]);

  useEffect(() => {
    setAuthService(
      new IceAuthService({ userManager: userManager, authStateResult: userUpdated, applicationInsightsService }),
    );
  }, []);

  // Set focus on toogle button after login redirect
  useEffect(() => {
    if (!buttonFocus) return;

    toggleButton?.current?.focus();

    setButtonFocus(false);
  }, [toggleButton, buttonFocus]);

  const logIn = async (): Promise<void> => {
    if (!authService) return;

    try {
      await authService.startSigninRedirect();
    } catch (error) {
      console.error("Beklager! Innloggingstjenesten var ikke tilgjengelig. Vennligst prøv igjen senere");
      applicationInsightsService.trackException(error as Error, "Innloggingstjenesten var ikke tilgjengelig");
    }
  };

  const logOut = async () => {
    if (!authService) return;
    await authService.signout();
  };

  const openProfilMenu = () => {
    if (closingProfilMenu) {
      return;
    }

    setShowProfilMenu(true);
  };

  const closeProfilMenu = () => {
    setShowProfilMenu(false);
  };

  useEffect(() => {
    if (showProfilMenu === true && profileMenu?.current !== null) {
      const firstMenuItem = profileMenu.current.querySelector<HTMLElement>(".profile-menu a");
      firstMenuItem?.focus();
    }
  }, [showProfilMenu, profileMenu]);

  const closeButtonClick = () => {
    closeProfilMenu();
    setButtonFocus(true);
  };

  const keyDownMenuButtonHandler = (event: KeyboardEvent) => {
    const el = event.target as HTMLElement;
    if (el.getAttribute("aria-expanded") !== "true") return;

    if (event.key === KEY_NAME.Escape) {
      closeProfilMenu();
      return;
    }
  };

  // need to block click on menubutton when focus out
  useEffect(() => {
    if (closingProfilMenu) {
      setTimeout(() => {
        setClosingProfilMenu(false);
      }, 200);
    }
  }, [closingProfilMenu]);

  const onFocusOut = (event: FocusEvent) => {
    const target = event.relatedTarget ? (event.relatedTarget as HTMLElement) : (event.target as HTMLElement);
    if (!target.closest("#profile-menu")) {
      setClosingProfilMenu(true);
      setShowProfilMenu(false);
    }
  };

  CloseWhenClickOutSideMenu(profileMenuContainer, closeProfilMenu);

  return (
    <div className={"header-tooltip"}>
      <VisuallyHidden role="alert">{message}</VisuallyHidden>
      {LoggedIn === MenuViewState.hidden && <div className="profile-placeholder" />}

      {LoggedIn === MenuViewState.closed && (
        <HeaderToolTip text="Din profil login" buttonId="profile-menu-login">
          <button
            type="button"
            onClick={logIn}
            className="btn btn-icon profile-btn"
            aria-label="Logg inn"
            data-id="Logg inn"
            id="profile-menu-login"
          >
            <CSSTransition in={showLoggedOutButton} classNames="profile-btn-tranition-out">
              <div className="profile-btn__inner" data-id="Logg inn">
                <svg style="width:4.8em;height:4.8em" focusable="false" aria-hidden="true">
                  <use xlinkHref="#ice-user" />
                </svg>
              </div>
            </CSSTransition>
          </button>
        </HeaderToolTip>
      )}

      {LoggedIn === MenuViewState.open && (
        <div className="login-container" ref={profileMenuContainer}>
          <HeaderToolTip text="Din profil" buttonId="profile-menu-button">
            <button
              type="button"
              onClick={openProfilMenu}
              onKeyDown={keyDownMenuButtonHandler}
              className="btn btn-icon profile-btn"
              aria-label="Profil meny"
              aria-expanded={showProfilMenu ? "true" : "false"}
              ref={toggleButton}
              aria-controls="profile-menu"
              id="profile-menu-button"
            >
              <div className="profile-btn__inner profile-btn__inner--circle" aria-hidden="true">
                {authState?.user?.initials}
              </div>
            </button>

            <div
              id="profile-menu"
              role="navigation"
              aria-labelledby="profile-menu-button"
              onKeyDown={keyDownMenuHandler(closeProfilMenu, setButtonFocus)}
              // eslint-disable-next-line react/no-unknown-property
              onFocusOut={onFocusOut}
            >
              <CSSTransition in={showProfilMenu} classNames="logindata-transition">
                <div className="profile-menu" ref={profileMenu}>
                  <div className="profile-menu__content" data-id="logged in dropdown box">
                    <ul className="list--unstyled">
                      <li className="profile-menu__header">
                        <span className="sid-subtitle-1" data-hj-suppress>
                          {authState?.user?.fullName}
                        </span>
                        <button
                          type="button"
                          className="no-default-button profile-menu__icon profile-menu__close-button"
                          onClick={closeButtonClick}
                          aria-label="Lukk meny"
                        >
                          <svg style="width:4.8em;height:4.8em" focusable="false" aria-hidden="true">
                            <use xlinkHref="#ice-close" />
                          </svg>
                        </button>
                      </li>
                      <li className="profile-menu__item profile-menu__item--first">
                        <a className="profile-menu__item__link" href={minSideBaseUrl} data-id="Min side">
                          <span className="profile-menu__icon">
                            <svg style="width:4.8em;height:4.8em" focusable="false" aria-hidden="true">
                              <use xlinkHref="#ice-user" />
                            </svg>
                          </span>
                          <span className="link--underline-yellow">Til Min Side</span>
                        </a>
                      </li>

                      <ShowCompanyAdmin hasCompanyAdmin={hasCompanyAdmin} minsideBaseUrl={minSideBaseUrl} />

                      <li className={"profile-menu__item mt-1"}>
                        <a className="profile-menu__item__link" href="/mobilabonnement">
                          <div className="link--underline-yellow" data-id="Kjøp mobilabonnement">
                            Kjøp mobilabonnement
                          </div>
                        </a>
                      </li>
                      <li className="profile-menu__item">
                        <a
                          className="profile-menu__item__link"
                          href="https://nettbutikk.ice.no/mobiltelefoner"
                          data-id="Kjøp mobiltelefon"
                        >
                          <div className="link--underline-yellow">Kjøp mobiltelefon</div>
                        </a>
                      </li>
                      <li className="profile-menu__item mt-1">
                        <a className="profile-menu__item__link" href={minSideProfil} data-id="Min profil">
                          <span className="profile-menu__icon">
                            <svg style="width:4.8em;height:4.8em" focusable="false" aria-hidden="true">
                              <use xlinkHref="#ice-settings" />
                            </svg>
                          </span>
                          <span>Min profil</span>
                        </a>
                      </li>
                      <li className="profile-menu__item profile-menu__item--last">
                        <button
                          type="button"
                          onClick={logOut}
                          className="no-default-button profile-menu__item__link"
                          data-id="Logg ut"
                        >
                          <span className="profile-menu__icon" data-id="Logg ut">
                            <svg style="width:4.8em;height:4.8em" focusable="false" aria-hidden="true">
                              <use xlinkHref="#ice-logout" />
                            </svg>
                          </span>
                          <span>Logg ut</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </CSSTransition>
            </div>
          </HeaderToolTip>
        </div>
      )}
    </div>
  );
};
