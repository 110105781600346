import { User, UserManager } from "oidc-client-ts";
import {
  getHasBeenLoggedIn,
  mapState,
  removeReturnUrl,
  setHasBeenLoggedIn,
  setReturnUrl,
} from "./customer-login-helpers";
import { AuthState, IceAuthServiceParams, LoginStates, LoginStateValues } from "./customer-login-interfaces";

export class IceAuthService {
  private _userManager: UserManager;
  private _userChanged: (user: AuthState) => void;

  constructor({ userManager, authStateResult: userChanged }: IceAuthServiceParams) {
    this._userManager = userManager;
    this._userChanged = userChanged;

    this._userManager.events.addUserUnloaded(() => {
      this.userUpdated(null, LoginStates.SignedOutSilent);
    });
    this._userManager.events.addUserSignedOut(async () => {
      await this.signout();
    });

    this._userManager.events.addUserSignedIn(async () => {
      await this.trySilentRenew();
    });

    this._userManager.events.addAccessTokenExpired(this.ExpiredCallback);

    void this.CheckIfSignedin();
  }

  CheckIfSignedin = async (): Promise<void> => {
    const signinCallback = async (): Promise<User> => {
      try {
        // check if returning back from authority server
        return (await this._userManager.signinCallback()) as User;
      } catch (e) {
        return null;
      }
    };

    const callbackUser = await signinCallback();
    if (callbackUser != null) {
      removeReturnUrl();
      if (!getHasBeenLoggedIn()) setHasBeenLoggedIn();

      this.userUpdated(callbackUser, getHasBeenLoggedIn() ? LoginStates.SignedIn : LoginStates.SignedInRedirect);
      return;
    }

    const user = await this._userManager.getUser();
    if (user != null) {
      this.userUpdated(user, LoginStates.SignedIn);
      return;
    }
    await this.trySilentRenew();
  };

  userUpdated = (user: User | null, loginState: LoginStateValues) => {
    this._userChanged(mapState(user, loginState));
  };

  ExpiredCallback = async () => {
    await this.signout();
    this.userUpdated(null, LoginStates.notSignedIn);
  };

  trySilentRenew = async () => {
    try {
      const user = await this._userManager.signinSilent();
      if (user != null) {
        this.userUpdated(user, LoginStates.SignedInSilent);
      }
    } catch (e) {
      this.userUpdated(null, LoginStates.notSignedIn);
    }
  };

  public signout = async () => {
    await this._userManager.signoutSilent();
    await this._userManager.revokeTokens();
  };

  public async startSigninRedirect() {
    setReturnUrl(window.location.href);
    await this._userManager.signinRedirect();
  }
}
